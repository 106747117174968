import React, { useEffect, useState } from "react"
import * as S from "./inventory.styles.jsx"
import BoatCard from "../../components/boat-card/boat-card.component"
import Grid from "@material-ui/core/Grid"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { graphql, useStaticQuery } from "gatsby"

const Inventory = ({ tabs }) => {
  const data = useStaticQuery(graphql`
    query inventoryBoats {
      allWpInventoryBoat {
        edges {
          node {
            title
            slug
            uri
            inventoryBoatsFields {
              featuredImagePageInventory {
                featuredImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
              }
              inventoryTypeInventory {
                type
              }
            }
          }
        }
      }
    }
  `)

  // const [selectedTab, setSelectedTab] = useState("trade_in")
  // const [selectedBoats, setSelectedBoats] = useState([])

  const inventoryBoatsData = data.allWpInventoryBoat.edges
  const tradeIn = inventoryBoatsData.filter(
    inventory =>
      inventory.node.inventoryBoatsFields.inventoryTypeInventory.type ===
      "trade_in"
  )
  const brokerage = inventoryBoatsData.filter(
    inventory =>
      inventory.node.inventoryBoatsFields.inventoryTypeInventory.type ===
      "brokerage"
  )
  return (
    <SectionWrapper bg="primary" contained>
      <S.GridContainer container spacing={2}>
        <Grid item xs={12}>
          <S.Title>{tabs[0]?.text}</S.Title>
        </Grid>
        {tradeIn.map(inventory => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <BoatCard
                name={inventory.node.title}
                url={inventory.node.uri}
                img={
                  inventory.node.inventoryBoatsFields.featuredImagePageInventory
                    .featuredImage.sourceUrl
                }
                urlChange
              />
            </Grid>
          )
        })}
      </S.GridContainer>
      <S.GridContainer container spacing={2}>
        <Grid item xs={12}>
          <S.Title>{tabs[1]?.text}</S.Title>
        </Grid>
        {brokerage.map(inventory => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <BoatCard
                name={inventory.node.title}
                url={inventory.node.uri}
                img={
                  inventory.node.inventoryBoatsFields.featuredImagePageInventory
                    .featuredImage.sourceUrl
                }
                urlChange
              />
            </Grid>
          )
        })}
      </S.GridContainer>
    </SectionWrapper>
  )
}
export default Inventory
