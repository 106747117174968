import React from "react"
import Layout from "../../components/layout"

import * as S from "../../styles/pages/inventory.styles"
import * as S2 from "../../styles/templates/page-boat.styles"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import SectionTitle from "../../components/section-title/section-title.component"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CustomButton from "../../components/custom-button/custom-button.component"
import HeroSlider from "../../components/hero-slider/hero-slider.component"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Gallery from "../../components/gallery/gallery.component"
import GeneralHero from "../../layouts/GeneralHero"

export const query = graphql`
  query PageInventoryBoat($id: String!) {
    wpInventoryBoat(id: { eq: $id }) {
      title
      seo {
        cornerstone
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
      }
      uri
      slug
      inventoryBoatsFields {
        boatDescription {
          description
          price
        }
        boatFeaturesInventory {
          features {
            content
            image {
              sourceUrl
              altText
            }
            rightPositionImage
            title
          }
        }
        boatInformationInventory {
          content
          leftButton {
            title
            url
          }
          rightButton {
            title
            url
          }
          title
        }
        heroGalleryInventory {
          images {
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
        }
        keyFeaturesInventory {
          features {
            name
            value
          }
          image {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
            altText
            title
          }
          leftButton {
            title
            url
          }
          rightButton {
            title
            url
          }
        }
      }
    }
  }
`

const PageInventoryBoat = ({ data }) => {
  const {
    title: pageName,
    seo,
    inventoryBoatsFields: {
      heroGalleryInventory: { images },
      boatDescription: { description, price },
      boatFeaturesInventory: { features },
      boatInformationInventory: { content, leftButton, rightButton, title },
      keyFeaturesInventory: {
        features: featuresKeyFeatures,
        image,
        leftButton: leftButtonKey,
        rightButton: rightButtonKey,
      },
    },
  } = data.wpInventoryBoat

  const Scroll = require("react-scroll")
  const scroller = Scroll.scroller
  const handleScrollTo = () => {
    scroller.scrollTo("jobsSection", {
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }

  return (
    <Layout seo={seo} pageName={pageName}>
      <GeneralHero image={images[0]?.image} />
      <SectionWrapper>
        <div>
          <S2.SliderText>
            {title && <SectionTitle>{title}</SectionTitle>}
            {content && parse(content)}
          </S2.SliderText>
          <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item>
              <S.InventoryButton
                onClick={handleScrollTo}
                className="inventoryButton"
              >
                {leftButton && leftButton.title}
              </S.InventoryButton>
            </Grid>
            <Grid item>
              <CustomButton onClick={handleScrollTo}>
                {rightButton && rightButton.title}
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </SectionWrapper>
      <S2.KeyFeaturesSection>
        <Container maxWidth="md">
          <SectionTitle mb={1} colorInherit>
            <span>KEY</span> FEATURES
          </SectionTitle>
          {featuresKeyFeatures && (
            <Grid container spacing={4} justifyContent="center">
              {featuresKeyFeatures.map(({ name, value }) => (
                <Grid item md={4}>
                  <S2.KeyFeatureName>{name}</S2.KeyFeatureName>
                  <Typography>{value}</Typography>
                </Grid>
              ))}
            </Grid>
          )}

          {/*{image &&*/}
          {/*<S2.ImageWrapper fluid={image.imageFile.childImageSharp.fluid}/>*/}
          {/*}*/}
          {/*<Grid container spacing={4} alignItems="center" justify='center'>*/}
          {/*  <Grid item>*/}
          {/*    <CustomButton href={leftButtonKey && leftButtonKey.url} className='lightBorder'>{leftButtonKey && leftButtonKey.title}</CustomButton>*/}
          {/*  </Grid>*/}
          {/*  <Grid item>*/}
          {/*    <CustomButton*/}
          {/*      href={rightButtonKey && rightButtonKey.url}>{rightButtonKey && rightButtonKey.title}</CustomButton>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </Container>
      </S2.KeyFeaturesSection>
      <Gallery slides={images} />
      <SectionWrapper>
        <Container>
          <S.InventoryDescription>
            {description ? parse(description) : ""}
            <span>{price}</span>
          </S.InventoryDescription>
        </Container>
      </SectionWrapper>
    </Layout>
  )
}

export default PageInventoryBoat
